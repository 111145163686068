import { Component, Prop } from 'vue-property-decorator';
import HeadManagement from '~/mixins/HeadManagement';
import { VueComponentMixin } from '~/utils/vue-component';
import { Banner, Breadcrumbs } from '~/components/organisms';
import { Button, Headline } from '~/components/atoms';
import WidgetRenderer from '~/components/templates/base/WidgetRenderer';
import HtmlRenderer from '~/components/templates/base/HtmlRenderer';
import TopLayerImage from '~/components/templates/common/TopLayerImage';
import imageHeroDefault from '~/assets/images/hero_default.jpg';
import { RegionPage } from '~/utils/views';
import { sidebarColumns } from '~/utils/sidebarColumns';
import createSeoTags, { createJsonLd, TYPE } from '~/utils/views/createSeoTags';
import { getUrlFromPath } from '~/app/core/router';
import { Widget } from '~/utils/views/widgets';
import { Style } from '~/components/atoms/button/Button';
import TranslationVoting from '~/components/templates/common/TranslationVoting';
import Advertisement from '~/components/organisms/advertisement/Advertisement';
import { anchorPreposition, createAnchorId } from '~/utils/anchor';

interface RegionView {
  data: RegionPage;
}

@Component
export default class Region
  extends VueComponentMixin<RegionView, {}>(HeadManagement)
  implements RegionView {
  @Prop({ required: true })
  public data!: RegionPage;

  protected get showSideColumn(): boolean {
    return (
      (this.data.showRightColumn &&
        Array.isArray(this.data.widgets.right) &&
        this.data.widgets.right.length > 0) ||
      this.data.banners.length > 0
    );
  }

  protected get firstWidget(): Widget | null {
    const widgets = [...this.data.widgets.main];
    return this.data.firstWidgetAsHero ? widgets.shift() || null : null;
  }

  protected get mainWidgets(): Widget[] {
    const widgets = [...this.data.widgets.main];
    if (this.data.firstWidgetAsHero) {
      widgets.shift();
    }
    return widgets;
  }

  protected get title() {
    return this.data.title;
  }

  public headTags(): string {
    return (
      createSeoTags({
        title: this.data.title,
        description: this.data.perex,
        canonicalUrl: getUrlFromPath(this.data.canonicalPath, this.$router),
        type: TYPE.ARTICLE,
        image: this.data.image,
        imagesToPreload: this.data.imagesToPreload,
        path: this.data.canonicalPath,
        mutations: this.data.availableCultures,
      }) +
      createJsonLd({
        image: this.data.imagesToPreload,
        title: this.data.title,
        created: this.data.created,
        modified: this.data.modified,
      })
    );
  }
  public render() {
    return (
      <v-container fluid class='pa-0' key={this.data.guid}>
        {this.renderHero()}

        {!this.data.hideBreadcrumbs && (
          <v-container>
            <v-row>
              <v-col cols={12} class='py-0'>
                <Breadcrumbs breadcrumbs={this.data.breadcrumbs} />
              </v-col>
            </v-row>
          </v-container>
        )}

        {(() => {
          if (this.data.content || this.data.perex) {
            return (
              <v-container id={`${anchorPreposition}content`}>
                <v-row justify='center'>
                  <v-col
                    cols={12}
                    md={this.showSideColumn ? 7 : 12}
                    lg={8}
                    class='py-0'
                  >
                    {(() => {
                      const content: JSX.Element[] = [];
                      if (this.data.perex) {
                        content.push(
                          <div class='czt-perex'>
                            <HtmlRenderer content={this.data.perex} />
                          </div>
                        );
                      }
                      if (this.data.content) {
                        content.push(
                          <div class='czt-richtext'>
                            <HtmlRenderer content={this.data.content} />
                          </div>
                        );
                      }
                      if (this.data.showAutomatedTranslationVoting) {
                        content.push(
                          <TranslationVoting
                            locale={this.$i18n.locale}
                            message={this.data.automatedTranslationMessage}
                            pageId={this.data.guid}
                          />
                        );
                      }
                      return content;
                    })()}
                  </v-col>
                  {this.showSideColumn && (
                    <v-col
                      {...{ attrs: sidebarColumns }}
                      class='py-0 px-0 px-md-3'
                    >
                      {this.renderSideContent()}
                    </v-col>
                  )}
                </v-row>
              </v-container>
            );
          } else if (this.showSideColumn) {
            return (
              <v-container>
                <v-row>
                  <v-col cols={12} class='py-0'>
                    {this.renderSideContent()}
                  </v-col>
                </v-row>
              </v-container>
            );
          }
        })()}
        {this.data.widgets.before.length > 0 && (
          <WidgetRenderer widgets={this.data.widgets.before} />
        )}
        {this.mainWidgets.length > 0 && (
          <WidgetRenderer widgets={this.mainWidgets} />
        )}
        {this.data.widgets.after.length > 0 && (
          <WidgetRenderer widgets={this.data.widgets.after} />
        )}
      </v-container>
    );
  }

  protected renderHero(): JSX.Element {
    let events = {};
    if (!this.data.anchorTitle) {
      events = {
        on: {
          click: this.anchorClick,
        },
      };
    }
    return this.firstWidget ? (
      <WidgetRenderer widgets={[this.firstWidget]} isFirst />
    ) : (
      <Banner
        backgroundImage={{
          src: this.data.image ? this.data.image : imageHeroDefault,
          alt: this.data.title + '',
        }}
        isFirst
        imageFilter={this.data.imageFilter}
        clickable={!this.data.anchorTitle}
        showArrow={!this.data.anchorTitle}
        {...events}
        title={this.data.title + ''}
        titleLevel={1}
        titlePosition={`${this.data.titlePosition}`}
      >
        <Headline level={3} light>
          {this.data.subtitle}
        </Headline>
        {(() => {
          if (this.data.anchorTitle) {
            return (
              <Button
                class='my-3'
                buttonStyle={Style.PRIMARY_LIGHT}
                onClick={this.anchorClick}
              >
                {this.data.anchorTitle}
              </Button>
            );
          }
        })()}
        <TopLayerImage pageData={this.data} />
      </Banner>
    );
  }

  protected renderSideContent() {
    return (
      <div class='czt-side-content'>
        <WidgetRenderer widgets={this.data.widgets.right} side />
        {this.data.banners.length > 0 && (
          <v-container>
            <v-row>
              <v-col col={12} class='py-0'>
                {this.data.banners.map((banner) => (
                  <Advertisement data={banner} />
                ))}
              </v-col>
            </v-row>
          </v-container>
        )}
      </div>
    );
  }

  protected anchorClick() {
    const anchorId = this.data.anchorCustom || this.data.anchor || 'content';
    const anchor = document.querySelector(
      createAnchorId(anchorId, !this.data.anchorCustom)
    );
    if (anchor && anchor instanceof HTMLElement) {
      this.$vuetify.goTo(anchor);
    } else {
      this.$vuetify.goTo(createAnchorId('content'));
    }
  }
}
